<template>
  <div>
    <el-dialog
      title="商品跟踪"
      :visible.sync="dialogVisible"
      width="50%"
      :before-close="onHandleClose"
      center
    >
      <el-descriptions class="mb-10" :column="2" size="medium">
        <!-- <el-descriptions-item label="商品主图：" style="align-items: center">
          <el-image
            v-if="detail?.product?.kv"
            :src="detail?.product?.kv"
            :preview-src-list="[detail?.product?.kv]"
            class="kvs pointer"
            alt=""
        /></el-descriptions-item> -->
        <el-descriptions-item label="商品名称：">
          {{ detail?.product?.title || "" }}
        </el-descriptions-item>
        <el-descriptions-item label="货号：">
          {{ detail?.product?.sku_code || "" }}
        </el-descriptions-item>
        <el-descriptions-item label="业务员：">
          {{ detail?.buyer_name || "" }}
        </el-descriptions-item>
        <el-descriptions-item label="订单号：">
          {{ detail?.order?.order_no || "" }}
        </el-descriptions-item>
        <el-descriptions-item label="订单支付时间：">
          {{ detail?.pay_at || "" }}
        </el-descriptions-item>
        <el-descriptions-item label="客户名称：">
          {{ detail?.member?.fullname || "" }}
        </el-descriptions-item>
        <el-descriptions-item label="客户电话：">
          {{ detail?.member?.mobile || "" }}
        </el-descriptions-item>
        <el-descriptions-item label="二级分类：">
          {{ detail?.product?.cate_name || "" }}
        </el-descriptions-item>
        <el-descriptions-item label="城市仓：">
          {{ detail?.logistics_name || "--" }}
        </el-descriptions-item>
        <el-descriptions-item label="自提点：">
          {{ detail?.delivery_name || "--" }}
        </el-descriptions-item>
        <el-descriptions-item label="集配中心：">
          {{ detail?.logistic_business_name || "--" }}
        </el-descriptions-item>
        <el-descriptions-item label="默认供货商：">
          {{ detail?.default_supplier_name || "--" }}
        </el-descriptions-item>
        <el-descriptions-item label="实际供货商：">
          {{ detail?.supplier_name || "--" }}
        </el-descriptions-item>
        <el-descriptions-item label="订货数量：">
          {{ detail?.origin_number || "--" }}
        </el-descriptions-item>
        <el-descriptions-item label="供货单号：">
          {{ detail?.supplier_order?.supplier_order_no || "--" }}
        </el-descriptions-item>
        <el-descriptions-item label="供货日期：">
          {{ detail?.supplier_order?.create_date || "--" }}
        </el-descriptions-item>
        <el-descriptions-item label="复核状态：">
          {{ detail?.supplier_order?.qc_status_txt || "--" }}
        </el-descriptions-item>
        <el-descriptions-item label="复核缺货数量：">
          {{ detail?.stockout_number || "--" }}
        </el-descriptions-item>
        <el-descriptions-item label="实际到货数量：">
          {{ detail?.review_number || "--" }}
        </el-descriptions-item>
        <el-descriptions-item label="售后状态：">
          {{ detail?.aftersale?.state_txt || "--" }}
        </el-descriptions-item>
        <el-descriptions-item label="售后单号：">
          {{ detail?.aftersale?.as_no || "--" }}
        </el-descriptions-item>
        <el-descriptions-item label="售后创建时间：">
          {{ detail?.aftersale?.create_time || "--" }}
        </el-descriptions-item>
        <el-descriptions-item label="售后完成时间：">
          {{ detail?.aftersale?.operator_at || "--" }}
        </el-descriptions-item>
      </el-descriptions>
    </el-dialog>
  </div>
</template>
<script>
export default {
  name: "GoodsInformationDialog",
  props: {},
  components: {},
  data() {
    return {
      dialogVisible: false,
      detail: {},
    };
  },
  methods: {
    /**
     * 初始化
     */
    onInitData(data) {
      console.log(data, "(data)==");
      this.dialogVisible = true;
      this.detail = data;
    },
    /**
     * 关闭操作
     */
    onHandleClose() {
      this.dialogVisible = false;
    },
  },
};
</script>
<style scoped lang="scss">
.kvs {
  width: 100px;
  height: 100px;
}
</style>
