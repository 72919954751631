var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            title: "商品跟踪",
            visible: _vm.dialogVisible,
            width: "50%",
            "before-close": _vm.onHandleClose,
            center: "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c(
            "el-descriptions",
            { staticClass: "mb-10", attrs: { column: 2, size: "medium" } },
            [
              _c("el-descriptions-item", { attrs: { label: "商品名称：" } }, [
                _vm._v(" " + _vm._s(_vm.detail?.product?.title || "") + " "),
              ]),
              _c("el-descriptions-item", { attrs: { label: "货号：" } }, [
                _vm._v(" " + _vm._s(_vm.detail?.product?.sku_code || "") + " "),
              ]),
              _c("el-descriptions-item", { attrs: { label: "业务员：" } }, [
                _vm._v(" " + _vm._s(_vm.detail?.buyer_name || "") + " "),
              ]),
              _c("el-descriptions-item", { attrs: { label: "订单号：" } }, [
                _vm._v(" " + _vm._s(_vm.detail?.order?.order_no || "") + " "),
              ]),
              _c(
                "el-descriptions-item",
                { attrs: { label: "订单支付时间：" } },
                [_vm._v(" " + _vm._s(_vm.detail?.pay_at || "") + " ")]
              ),
              _c("el-descriptions-item", { attrs: { label: "客户名称：" } }, [
                _vm._v(" " + _vm._s(_vm.detail?.member?.fullname || "") + " "),
              ]),
              _c("el-descriptions-item", { attrs: { label: "客户电话：" } }, [
                _vm._v(" " + _vm._s(_vm.detail?.member?.mobile || "") + " "),
              ]),
              _c("el-descriptions-item", { attrs: { label: "二级分类：" } }, [
                _vm._v(
                  " " + _vm._s(_vm.detail?.product?.cate_name || "") + " "
                ),
              ]),
              _c("el-descriptions-item", { attrs: { label: "城市仓：" } }, [
                _vm._v(" " + _vm._s(_vm.detail?.logistics_name || "--") + " "),
              ]),
              _c("el-descriptions-item", { attrs: { label: "自提点：" } }, [
                _vm._v(" " + _vm._s(_vm.detail?.delivery_name || "--") + " "),
              ]),
              _c("el-descriptions-item", { attrs: { label: "集配中心：" } }, [
                _vm._v(
                  " " + _vm._s(_vm.detail?.logistic_business_name || "--") + " "
                ),
              ]),
              _c("el-descriptions-item", { attrs: { label: "默认供货商：" } }, [
                _vm._v(
                  " " + _vm._s(_vm.detail?.default_supplier_name || "--") + " "
                ),
              ]),
              _c("el-descriptions-item", { attrs: { label: "实际供货商：" } }, [
                _vm._v(" " + _vm._s(_vm.detail?.supplier_name || "--") + " "),
              ]),
              _c("el-descriptions-item", { attrs: { label: "订货数量：" } }, [
                _vm._v(" " + _vm._s(_vm.detail?.origin_number || "--") + " "),
              ]),
              _c("el-descriptions-item", { attrs: { label: "供货单号：" } }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.detail?.supplier_order?.supplier_order_no || "--"
                    ) +
                    " "
                ),
              ]),
              _c("el-descriptions-item", { attrs: { label: "供货日期：" } }, [
                _vm._v(
                  " " +
                    _vm._s(_vm.detail?.supplier_order?.create_date || "--") +
                    " "
                ),
              ]),
              _c("el-descriptions-item", { attrs: { label: "复核状态：" } }, [
                _vm._v(
                  " " +
                    _vm._s(_vm.detail?.supplier_order?.qc_status_txt || "--") +
                    " "
                ),
              ]),
              _c(
                "el-descriptions-item",
                { attrs: { label: "复核缺货数量：" } },
                [
                  _vm._v(
                    " " + _vm._s(_vm.detail?.stockout_number || "--") + " "
                  ),
                ]
              ),
              _c(
                "el-descriptions-item",
                { attrs: { label: "实际到货数量：" } },
                [_vm._v(" " + _vm._s(_vm.detail?.review_number || "--") + " ")]
              ),
              _c("el-descriptions-item", { attrs: { label: "售后状态：" } }, [
                _vm._v(
                  " " + _vm._s(_vm.detail?.aftersale?.state_txt || "--") + " "
                ),
              ]),
              _c("el-descriptions-item", { attrs: { label: "售后单号：" } }, [
                _vm._v(
                  " " + _vm._s(_vm.detail?.aftersale?.as_no || "--") + " "
                ),
              ]),
              _c(
                "el-descriptions-item",
                { attrs: { label: "售后创建时间：" } },
                [
                  _vm._v(
                    " " +
                      _vm._s(_vm.detail?.aftersale?.create_time || "--") +
                      " "
                  ),
                ]
              ),
              _c(
                "el-descriptions-item",
                { attrs: { label: "售后完成时间：" } },
                [
                  _vm._v(
                    " " +
                      _vm._s(_vm.detail?.aftersale?.operator_at || "--") +
                      " "
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }